import { DataConstant } from "../../../constants/dataConstant";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const appActionTypes = {
  SetParentBusinessDetails: "[Set ParentBusiness Details] Action",
  SetChildBusinessCodeList: "[Set ChildBusiness List] Action",
  UpdateAffiliateDetails: "[Update Affiliate Details] Action",
  ClearAffiliateDetails: "[Clear Affiliate Details] Action",
  ClearChildBusinessCodeList: "[Clear ChildBusiness List] Action",
  ClearParentBusinessDetails: "[Clear ParentBusiness Details] Action",
  ClearParentAffiliateChildBusinessDetails: "[Clear ParentAffiliateChildBusiness Details] Action",
  SetShowAffiliatePromoCodeModal: "[Clear AffliatePromoCodeDetails] Action",
};

export const initialAppState = {
  parentBusinessDetails: {
    businessId: undefined,
    businessName: undefined,
    businessState: undefined,
    businessCountry: undefined,
    businessPhoneNumber: undefined,
    businessProfilePic: undefined,
    businessDescription: undefined,
    businessAdddress: undefined,
  },
  childBusinessCodeList: [],
  affiliateDetails: {
    url: "",
    code: "",
    affiliateId: 0,
    promoCode: "",
  },
  showAffilitatePromoCodeModal: false,
  affiliatePromoCodeDetails:{}
};

export const appReducer = persistReducer(
  {
    storage,
    key: "app-detail",
  },
  (state = initialAppState, action) => {
    switch (action.type) {
      case appActionTypes.SetParentBusinessDetails: {
        const { 
          businessId,
          businessName,
          businessState,
          businessCountry,
          businessPhoneNumber,
          businessProfilePic,
          businessDescription,
          businessAdddress,
        } = action.payload;
        return { 
          ...state,  
          parentBusinessDetails: {
            businessId: businessId,
            businessName: businessName,
            businessState: businessState,
            businessCountry: businessCountry,
            businessPhoneNumber: businessPhoneNumber,
            businessProfilePic: businessProfilePic,
            businessDescription: businessDescription,
            businessAdddress: businessAdddress,
          }
        }
      };
      case appActionTypes.SetChildBusinessCodeList: {
        if (!Array.isArray(action.payload)) return { ...state, childBusinessCodeList: [] };
        return { ...state, childBusinessCodeList: action.payload };
      }
      case appActionTypes.UpdateAffiliateDetails: {
        return { ...state, affiliateDetails: {...state.affiliateDetails, ...action.payload} };
      }
      case appActionTypes.ClearAffiliateDetails: {
        return { ...state, affiliateDetails: initialAppState.affiliateDetails};
      }
      case appActionTypes.ClearChildBusinessCodeList: {
        return { ...state, childBusinessCodeList: initialAppState.childBusinessCodeList};
      }
      case appActionTypes.ClearParentBusinessDetails: {
        return { ...state, parentBusinessDetails: initialAppState.parentBusinessDetails};
      }
      case appActionTypes.ClearParentAffiliateChildBusinessDetails: {
        return {
          ...state,
          parentBusinessDetails: initialAppState.parentBusinessDetails,
          childBusinessCodeList: initialAppState.childBusinessCodeList,
          affiliateDetails: initialAppState.affiliateDetails
        };
      }
      case appActionTypes.SetShowAffiliatePromoCodeModal: {
              const { showAffilitatePromoCodeModal,affiliatePromoCodeDetails } = action.payload;
              return { ...state, showAffilitatePromoCodeModal,affiliatePromoCodeDetails };
            }
      default:
        return state;
    }
  });
