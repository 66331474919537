import { DataConstant } from "../../../constants/dataConstant";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getCurrentDate } from "../../../../utils/helper";
export const actionTypes = {
  SetWidgetToken: "[Set WidgetToken] Action",
  SetWidgetTab: "[Set WidgetTab] Action",
  SetBookingChoice: "[Set BookingChoice] Action",
  SetFeatureFlag: "[SetFeatureFlag] Action",
  UpdateBusinessDetail: "[Update BusinessDetail] Action",
  UpdateWhiteLabelDetails: "[Get UpdateWhiteLabelDetails]",
  SetServiceCategories: "[Set ServiceCategory] Action",
  SetLocationDetail: "[Set LocationDetail] Action",
  SetServiceDetail: "[Set Service] Action",
  SetServiceProviderDetail: "[Set ServiceProvider] Action",
  SetFacilityDetail: "[Set Facility Detail] Action",
  SetNotes: "[Set Notes] Action",
  SetCurrentLocation: "[Set CurrentLocation] Action",
  SetBookingSummary: "[Set BookingSummary] Action",
  SetMembershipPlanType: "[Set MembershipPlanType] Action",
  SetCardDetail: "[Set CardDetail] Action",
  SetUserBookingDetail: "[Set UserBooking] Action",
  ClearBookingDetail: "[Clear BookingDetail] Action",
  ClearBookingDetailAfterLogout: "[Clear BookingDetail After logout] Action",
  SetUserTypeDetail: "[Set UserTypeDetail] Action",
  SetMembershipCredit: "[Set MembershipCredit] Action",
  SetMembershipService: "[Set MembershipService] Action",
  SetMembershipDiscountSequence: "[Set MembershipDiscountSequence] Action",
  SetEntityDetail: "[Set EntityDetail] Action",
  SetServiceProviderProfileDetail: "[Set ServiceProviderProfileDetail] Action",
  ClearUserDetail: "[Clear UserDetail] Action",
  Clear401Page: "[Clear 401Page] Action",
  SetWidgetPopup: "[Set WidgetPopup] Action",
  SetServiceCategorieList: "[Set ServiceCategorieList] Action",
  SetUserBookingDetailResponse: "[Set UserBookingDetailResponse] Action",
  SetReassessmentBookingDetailResponse: "[Set ReassessmentBookingDetailResponse] Action",
  SetCurrentBookingLabTestDetails: "[Set SetCurrentBookingLabTestDetails] Action",
  SetIsDummyBookingApiCalled: "[Set SetIsDummyBookingApiCalled] Action",
  SetServiceDetailByIdResponse: "[Set ServiceDetailByIdResponse] Action",
  SetUserBookingServiceConsultListResponse: "[Set UserBookingServiceConsultListResponse] Action",
  SetUploadedDocumentDetails: "[Set UploadedDocumentDetails] Action",
  SetUserBookingClientId: "[Set UserBookingClientId] Action",
  SetUserBookingId: "[Set UserBookingId] Action",
  SetBookingStatus: "[Set BookingStatus] Action",
  SetUserClientDetailId: "[Set UserClientDetailId] Action",
  SetGoToMedicalConsent: "[Set GoToMedicalConsent] Action",
  SetClientDetails: "[Set ClientDetails] Action",
  SetSelectedServiceDetails: "[Set SelectedServiceDetails] Action",
  SetBookingDetailResponse: "[Set BookingDetailResponse] Action",
  SetAddClientDetailForConsultation: "[Set AddClientDetailForConsultation] Action",
  SetMobileMedIvServiceBusinessFlow: "[Set MobileMedIvServiceBusinessFlow] Action",
  SetFormClientUserDetailId: "[Set FormClientUserDetailId] Action",
  SetDummyBookingFlow: "[Set DummyBookingFlow] Action",
  SetRedirectToBookingDetailPage: "[Set RedirectBookingDetailPage] Action",
  SetServiceConsultBookingResponse: "[Set ServiceConsultBookingResponse] Action",
  ClearMobileMedIvFlow: "[Clear MobileMedIvFlow] Action",
  SetRedirectToCancelBookingPage: "[Set RedirectToCancelBookingPage] Action",
  SetBackFromConsultationSummaryPage: "[Set BackFromConsultationSummaryPage] Action",
  setConsultationListPage: "[Set ConsultationListPage]",
  setPreAssessmentDetails: "[Set PreassessmentDetails]",
  updateLastServiceConsultAndConsultRequired: "Update lastServiceConsultAndConsultDetails for PSF",
  updateOrderMedicationDetails: "[Set Medication details for ordring PSF medication]",
  SetRedirectToNewCardFromMeds: "[Set RedirectToNewCardFromMeds] Action",
  ClearRedirectToNewCardFromMeds: "[Clear RedirectToNewCardFromMeds] Action",
  ClearWidgetDetails: "[Clear Widget details] Action",
  updateConsultAfterLabDetails: "[Update ConsultAfterLabDetails] Action",
  updateParentWidgetDetails: "[Update ParentWidgetDetails] Action",
  clearParentWidgetDetails: "[Clear ParentWidgetDetails] Action",
};

export const initialWidgetState = {
  widgetToken: "",
  googleAnalyticsId : "",
  widgetUrl:"",
  parentWidgetDetails: {
    url: "",
    code: "",
    affiliateId: "",
    promoCode: "",
  },
  widgetCode: "",
  userDetailId: 0,
  userTypeId: 0,
  userId: 0,
  userType: "",
  isTestAccount: false,
  currentTab: DataConstant.tabs.widgetMain,
  entityId: 0,
  entityType: '',
  headerTitle: '',
  isBusiness: true,
  isNewTabOpen: false,
  serviceProviderProfileDetail: {
    serviceProviderUserDetailId: 0,
    serviceProviderName: '',
    serviceProviderReview: 0,
    serviceProviderProfileImage: null,
    serviceProviderDefaultBusinessId: 0,
    serviceProviderBioDetail: ''
  },
  isCallOutService: false,
  isDisplayBookingChoice: false,
  selectedServiceCategoriesList: [],
  serviceCategoriesList: [],
  isOtherTimeSlotRedirect: false,
  businessDetail: {
    businessId: 0,
    businessName: '',
    businessAdddress: '',
    businessProfilePic: '',
    businessRating: 0,
    noPenaltyUptoHours: 0,
    cancelledBookingCharge: 0,
    businessZipcode: '',
    businessCountry: '',
    businessDescription: '',
    businessTagline: '',
    stateId: 0,
    isCallOutService: false,
    businessState: '',
    isDripBarBusiness: false
  },
  locationDetail: {
    searchText: '',
    address: '',
    lat: '',
    long: '',
    country: '',
    zipcode: ''
  },
  currentLocationDetail: {
    lat: '',
    long: '',
  },
  ServiceDetail: {
    selectedServiceList: [],
    selectedAddOnList: [],
    groupDiscountPercentage: 0,
    noofPersonForGroupDiscount: 0,
    totalAmount: 0,
    isPSFServiceSelected: false,
    labsRequired: false,
    preAssessmentRequired: false,
  },
  selectedBookingDate: getCurrentDate(new Date()),
  serviceProviderDetail: {
    userDetailId: 0,
    name: "",
    profileImage: "",
    ratings: 0,
    travelFee: 0,
    workingDateTime: "",
    workingTimeFormat: "",
    selectedTimeSlot: "",
    bookingTime: "",
    selectedTimeSlotIndex: "",
    timeZoneName: '',
    timeZoneShortName: '',
    slots: [],
    distance: 0,
    lat: 0,
    long: 0,
    location: '',
    clubReadyInstructorId: '',
    clubReadyClubId: '',
    userId: 0,
    defaultBusinessId: 0
  },
  serviceModel: {
    serviceProviderId: 0,
    serviceProviderName: "",
    clientBookingLocation: "",
    clientBookingLat: "",
    clientBookingLong: "",
    bookingServiceDetailRequest: [],
    bookingServiceProviderList: []
  },
  facilityModel: {
    facilityId: 0,
    facilityName: "",
    facilityLocation: "",
    noPenaltyUptoHours: 0,
    cancelledBookingCharge: 0,
    businessClubId: 0,
    isClubReady: false,
    lat: null,
    long: null
  },
  notes: '',
  membershipPlanDetail: {
    userMembershipId: 0,
    isMembership: false,
    planName: '',
    planType: '',
    balanceCredits: 0,
    pricePerCredit: 0,
    discount: 0,
    isApplyMembership: false,
    isMembershipCredit: false,
    isMembershipService: false,
    cancellationPolicyHours: 0,
    cancellationPolicyFeePercentage: 0,
    standardPriceForCredit: 0,
    annualMonthlyPrice: 0,
    serviceDetailList: [],
    cycleStartDate: "",
    cycleEndDate: "",
  },
  discountDetail: {
    totalServiceFee: 0,
    complementaryDiscountAmount: 0,
    groupPersonCount: 0,
    groupDiscount: 0,
    groupDiscountAmount: 0,
    insuranceCoverAmount: 0,
    creditUsed: 0,
    pricePerCredit: 0,
    creditDiscountAmount: 0,
    rewardClubId: 0,
    rewardClubDiscountType: '',
    rewardClubDiscountValue: 0,
    rewardClubConsumedFreeIVCount: 0,
    rewardClubConsumedFreeIVTotalAmount: 0,
    rewardClubTimeFrameDays: 0,
    rewardClubFromCycleDate: null,
    rewardClubToCycleDate: null,
    rewardClubDiscountAmount: 0,
    rewardClubClientMappingId: 0,
    couponId: 0,
    couponCode: '',
    couponType: '',
    couponDiscountType: '',
    couponDiscountValue: 0,
    couponDiscountAmount: 0,
    couponDiscountLabel: '',
    couponMessage: '',
    totalRemainingAmount: 0,
    serviceTravelingFee: 0,
    totalPaymentAmount: 0,
    membershipDiscountType: '',
    membershipCreditBalance: 0,
    membershipServiceBalance: [],
    serviceDetails: [],
    serviceBookingCalculateDiscountSequence: [],
    userBookingServiceDetails: [],
    discountSequence: "",
    sequenceData: [],
    paymentDetails: []
  },
  defaultCardDetail: {
    userPaymentProfileId: 0,
    userDetailId: 0,
    userPGPaymentProfileId: '',
    cardType: '',
    cardLastFourDigits: '',
    expiryDate: '',
    userPGProfileId: '',
    userPaymentGatewayDetailId: 0
  },
  clientDetails: [],
  membershipCreditUsed: 0,
  membershipServiceAmount: 0,
  membershipPlanType: '',
  userBookingId: 0,
  isWidgetPopup: false,
  servicePackFormId: 0,
  userBookingDetails: {
    bookingBusinessId: 0,
    bookingUTCDateTime: '',
    bookingTimeZoneDate: '',
    bookingTimeZoneTime: '',
    userBookingId: 0,
    clientDetails: [],
    userBookingServiceDetails: [],
    userPGPaymentProfileId: 0,
    userPaymentGatewayDetailId: 0,
    isCRFacilityBooking: false,
    bookingFacilityId : 0,
    phone:'',
    packageFeeExcludingLab: 0,
  },
  serviceDetailsById:[],
  uploadedDocumentDetails: {
    createdByEmail: '',
    createdByName: '',
    createdOn: '',
    fileExtension: '',
    filePath: '',
    mediaId: 0,
    mediaType: '',
    originalFileName: '',
    refId: 0
  },
  userBookingClientId: 0,
  bookingStatus: "",
  clientUserDetailId: 0,
  // bookingDetailResponse : {
  //   userBookingId : 0,
  //   clientDetails : [],
  //   userBookingServiceDetails : [],
  // },
  service: [],
  addClientDetailForConsultation: {
    email: '',
    firstName: '',
    lastName: '',
    password: null,
    phone: ''
  },
  isAddClient: false,
  isMobileMedIvServiceFlow: false,
  mobileMedIvServiceCode: '',
  formClientUserDetailId: 0,
  isRedirectToBookingDetailPage: false,
  isRedirectToPSFBookingDetailPage: false,
  serviceConsultForBookingList: [],
  isRedirectToCancelBookingPage: false,
  isBackFromConsultationSummaryPage: false,
  isBackFromConsultationListPage: false,
  preAssessmentDetails: {},
  isServiceConsultRequired: true,
  lastServiceConsultDetails: [],
  psfOrderMedication: {},
  featureFlags: null,
  isRedirectToNewCardFromMeds: false,
  whiteLabelDetails: {},
  userBookingServiceConsultList : [],
  consultAfterLabDetails: {},
};

export const widgetReducer = persistReducer(
  {
    storage,
    key: "widget-detail",
  },
  (state = initialWidgetState, action) => {
    switch (action.type) {
      case actionTypes.SetWidgetToken: {
        const { widgetToken, widgetUrl, googleAnalyticsId, widgetCode } = action.payload;
        return { ...state, widgetToken, widgetUrl, googleAnalyticsId, widgetCode };
      }
      case actionTypes.SetWidgetTab: {
        const { currentTab } = action.payload;
        return { ...state, currentTab };
      }
      case actionTypes.SetBookingChoice: {
        const { isCallOutService, isDisplayBookingChoice } = action.payload;
        return { ...state, isCallOutService, isDisplayBookingChoice };
      }
      case actionTypes.UpdateBusinessDetail: {
        return { ...state, businessDetail: { ...state.businessDetail, ...action.payload} };
      }
      case actionTypes.SetFeatureFlag: {
        return { ...state, featureFlags: action.payload };
      }
      case actionTypes.SetServiceCategories: {
        const { selectedServiceCategoriesList } = action.payload;
        return { ...state, selectedServiceCategoriesList };
      }
      case actionTypes.SetLocationDetail: {
        return { ...state, locationDetail: action.payload };
      }
      case actionTypes.SetServiceDetail: {
        return { ...state, ServiceDetail: action.payload };
      }
      case actionTypes.SetServiceProviderDetail: {
        const { serviceProviderDetail, selectedBookingDate, isOtherTimeSlotRedirect } = action.payload;
        return { ...state, serviceProviderDetail: serviceProviderDetail, selectedBookingDate: selectedBookingDate, isOtherTimeSlotRedirect: isOtherTimeSlotRedirect };
      };
      case actionTypes.SetFacilityDetail: {
        const { facilityModel } = action.payload;
        return { ...state, facilityModel: facilityModel };
      }
      case actionTypes.SetNotes: {
        const { notes } = action.payload;
        return { ...state, notes: notes };
      }
      case actionTypes.SetCurrentLocation: {
        return { ...state, currentLocationDetail: action.payload };
      }
      case actionTypes.SetBookingSummary: {
        const { membershipPlanDetail, discountDetail } = action.payload;
        return { ...state, membershipPlanDetail: membershipPlanDetail, discountDetail: discountDetail };
      }
      case actionTypes.SetMembershipPlanType: {
        return { ...state, membershipPlanType: action.payload };
      }
      case actionTypes.SetMembershipCredit: {
        const { membershipCreditUsed } = action.payload;
        return { ...state, membershipCreditUsed: membershipCreditUsed };
      }
      case actionTypes.SetMembershipService: {
        const { membershipServiceAmount, services } = action.payload;
        return { ...state, membershipServiceAmount: membershipServiceAmount, ServiceDetail: { ...state.ServiceDetail, selectedServiceList: services } };
      }
      case actionTypes.SetCardDetail: {
        const { defaultCardDetail } = action.payload;
        return { ...state, defaultCardDetail: defaultCardDetail };
      }
      case actionTypes.SetUserBookingDetail: {
        const { userBookingId, servicePackFormId } = action.payload;
        return { ...state, userBookingId, servicePackFormId };
      }
      case actionTypes.ClearBookingDetail: {
        return {
          ...state, currentTab: initialWidgetState.currentTab, serviceProviderProfileDetail: initialWidgetState.serviceProviderProfileDetail,
          isCallOutService: initialWidgetState.isCallOutService, isDisplayBookingChoice: initialWidgetState.isDisplayBookingChoice,
          selectedServiceCategoriesList: initialWidgetState.selectedServiceCategoriesList, serviceCategoriesList: initialWidgetState.serviceCategoriesList,
          isOtherTimeSlotRedirect: initialWidgetState.isOtherTimeSlotRedirect, ServiceDetail: initialWidgetState.ServiceDetail,
          selectedBookingDate: initialWidgetState.selectedBookingDate, serviceProviderDetail: initialWidgetState.serviceProviderDetail,
          serviceModel: initialWidgetState.serviceModel, facilityModel: initialWidgetState.facilityModel, notes: initialWidgetState.notes,
          membershipPlanDetail: initialWidgetState.membershipPlanDetail, discountDetail: initialWidgetState.discountDetail,
          defaultCardDetail: initialWidgetState.defaultCardDetail, clientDetails: initialWidgetState.clientDetails,
          membershipCreditUsed: initialWidgetState.membershipCreditUsed, membershipServiceAmount: initialWidgetState.membershipServiceAmount,
          membershipPlanType: initialWidgetState.membershipPlanType, userBookingId: initialWidgetState.userBookingId,
          servicePackFormId: initialWidgetState.servicePackFormId, userBookingDetails: initialWidgetState.userBookingDetails,
          uploadedDocumentDetails: initialWidgetState.uploadedDocumentDetails, userBookingClientId: initialWidgetState.userBookingClientId,
          bookingStatus: initialWidgetState.bookingStatus, clientUserDetailId: initialWidgetState.clientUserDetailId,
          service: initialWidgetState.service, addClientDetailForConsultation: initialWidgetState.addClientDetailForConsultation,
          isAddClient: initialWidgetState.isAddClient, formClientUserDetailId: initialWidgetState.formClientUserDetailId,
          isRedirectToBookingDetailPage: initialWidgetState.isRedirectToBookingDetailPage, isRedirectToPSFBookingDetailPage: initialWidgetState.isRedirectToPSFBookingDetailPage,
          serviceConsultForBookingList: initialWidgetState.serviceConsultForBookingList , isRedirectToCancelBookingPage : initialWidgetState.isRedirectToCancelBookingPage,
          isBackFromConsultationSummaryPage : initialWidgetState.isBackFromConsultationSummaryPage, isBackFromConsultationListPage: initialWidgetState.isBackFromConsultationListPage,
          consultAfterLabDetails: initialWidgetState.consultAfterLabDetails,
        };
      }
      case actionTypes.ClearBookingDetailAfterLogout: {
        return {
          ...state, currentTab: initialWidgetState.currentTab, isOtherTimeSlotRedirect: initialWidgetState.isOtherTimeSlotRedirect, locationDetail: initialWidgetState.locationDetail,
          serviceModel: initialWidgetState.serviceModel, facilityModel: initialWidgetState.facilityModel, notes: initialWidgetState.notes,
          membershipPlanDetail: initialWidgetState.membershipPlanDetail, discountDetail: initialWidgetState.discountDetail,
          defaultCardDetail: initialWidgetState.defaultCardDetail, clientDetails: initialWidgetState.clientDetails,
          membershipCreditUsed: initialWidgetState.membershipCreditUsed, membershipServiceAmount: initialWidgetState.membershipServiceAmount,
          membershipPlanType: initialWidgetState.membershipPlanType, userBookingId: initialWidgetState.userBookingId,
          servicePackFormId: initialWidgetState.servicePackFormId, userBookingDetails: initialWidgetState.userBookingDetails,
          uploadedDocumentDetails: initialWidgetState.uploadedDocumentDetails, userBookingClientId: initialWidgetState.userBookingClientId,
          bookingStatus: initialWidgetState.bookingStatus, clientUserDetailId: initialWidgetState.clientUserDetailId,
          service: initialWidgetState.service, addClientDetailForConsultation: initialWidgetState.addClientDetailForConsultation,
          isAddClient: initialWidgetState.isAddClient, formClientUserDetailId: initialWidgetState.formClientUserDetailId,
          isRedirectToBookingDetailPage: initialWidgetState.isRedirectToBookingDetailPage, isRedirectToPSFBookingDetailPage: initialWidgetState.isRedirectToPSFBookingDetailPage,
          serviceConsultForBookingList: initialWidgetState.serviceConsultForBookingList , isRedirectToCancelBookingPage : initialWidgetState.isRedirectToCancelBookingPage,
          isBackFromConsultationSummaryPage : initialWidgetState.isBackFromConsultationSummaryPage, isBackFromConsultationListPage: initialWidgetState.isBackFromConsultationListPage,
          userDetailId: initialWidgetState.userDetailId,
        };
      }
      case actionTypes.SetUserTypeDetail: {
        const { userDetailId, userTypeId, userType, userId, isTestAccount, isDripBarBusiness, affiliateUserDetailId } = action.payload;
        return { ...state, userDetailId: userDetailId, userTypeId: userTypeId, userType: userType, userId: userId, isTestAccount: isTestAccount, isDripBarBusiness:  isDripBarBusiness, affiliateUserDetailId: affiliateUserDetailId};
      }
      case actionTypes.SetMembershipDiscountSequence: {
        const { sequenceData } = action.payload;
        return { ...state, discountDetail: { ...state.discountDetail, sequenceData: sequenceData } };
      }
      case actionTypes.SetEntityDetail: {
        const { entityId, entityType, isBusiness, isNewTabOpen } = action.payload;
        return { ...state, entityId: entityId, entityType: entityType, isBusiness: isBusiness, isNewTabOpen: isNewTabOpen, headerTitle: action.payload?.headerTitle };
      }
      case actionTypes.SetServiceProviderProfileDetail: {
        return { ...state, serviceProviderProfileDetail: action.payload };
      }
      case actionTypes.ClearUserDetail: {
        return {
          ...state, userType: initialWidgetState.userType, userTypeId: initialWidgetState.userTypeId,
          userDetailId: initialWidgetState.userDetailId, userId: initialWidgetState?.userId, isTestAccount: initialWidgetState?.isTestAccount
        };
      }
      case actionTypes.Clear401Page: {
        return {
          ...state, userDetailId: initialWidgetState.userDetailId, userTypeId: initialWidgetState.userTypeId,
          userId: initialWidgetState.userId, isTestAccount: initialWidgetState.isTestAccount, currentTab: DataConstant.tabs.serviceProvider, isOtherTimeSlotRedirect: initialWidgetState.isOtherTimeSlotRedirect,
          notes: initialWidgetState.notes, membershipPlanDetail: initialWidgetState.membershipPlanDetail, discountDetail: initialWidgetState.discountDetail,
          defaultCardDetail: initialWidgetState.defaultCardDetail, membershipCreditUsed: initialWidgetState.membershipCreditUsed, membershipServiceAmount: initialWidgetState.membershipServiceAmount,
          membershipPlanType: initialWidgetState.membershipPlanType, userBookingId: initialWidgetState.userBookingId
        };
      }
      case actionTypes.SetWidgetPopup: {
        const { isWidgetPopup } = action.payload;
        return { ...state, isWidgetPopup: isWidgetPopup };
      }
      case actionTypes.SetServiceCategorieList: {
        const { serviceCategoriesList } = action.payload;
        return { ...state, serviceCategoriesList };
      }
      case actionTypes.SetUserBookingDetailResponse: {
        const { userBookingDetails } = action.payload;
        return { ...state, userBookingDetails: userBookingDetails };
      }
      case actionTypes.SetReassessmentBookingDetailResponse: {
        const { reassessmentBookingDetails } = action.payload;
        return { ...state, reassessmentBookingDetails: reassessmentBookingDetails };
      } 
      case actionTypes.SetCurrentBookingLabTestDetails: {
        const { currentBookingLabTestDetails } = action.payload;
        return { ...state, currentBookingLabTestDetails: currentBookingLabTestDetails };
      }
      case actionTypes.SetIsDummyBookingApiCalled: {
        const { isDummyBookingApiCalled } = action.payload;
        return { ...state, isDummyBookingApiCalled: isDummyBookingApiCalled };
      }
      case actionTypes.SetServiceDetailByIdResponse: {
        const { serviceDetailsById } = action.payload;
        return { ...state, serviceDetailsById: serviceDetailsById };
      }
      case actionTypes.SetUserBookingServiceConsultListResponse: {
        const { userBookingServiceConsultList } = action.payload;
        return { ...state, userBookingServiceConsultList: userBookingServiceConsultList };
      }
      case actionTypes.SetUploadedDocumentDetails: {
        const { uploadedDocumentDetails } = action.payload;
        return { ...state, uploadedDocumentDetails: uploadedDocumentDetails };
      }
      case actionTypes.SetUserBookingClientId: {
        const { userBookingClientId } = action.payload;
        return { ...state, userBookingClientId: userBookingClientId };
      }
      case actionTypes.SetUserBookingId: {
        const { userBookingId } = action.payload;
        return { ...state, userBookingId: userBookingId };
      }
      case actionTypes.SetBookingStatus: {
        const { bookingStatus } = action.payload;
        return { ...state, bookingStatus: bookingStatus };
      }
      case actionTypes.SetGoToMedicalConsent: {
        const { currentTab, clientUserDetailId } = action.payload;
        return { ...state, currentTab: currentTab, clientUserDetailId: clientUserDetailId };
      }
      case actionTypes.SetClientDetails: {
        const { clientDetails } = action.payload;
        return { ...state, clientDetails: clientDetails };
      }
      case actionTypes.SetSelectedServiceDetails: {
        const { service } = action.payload;
        return { ...state, service: service };
      }
      case actionTypes.SetBookingDetailResponse: {
        const { userBookingId, clientDetails, userBookingServiceDetails } = action.payload;
        return { ...state, userBookingDetails: { ...state.userBookingDetails, userBookingId: userBookingId, clientDetails: clientDetails, userBookingServiceDetails: userBookingServiceDetails } };
      }
      case actionTypes.SetAddClientDetailForConsultation: {
        const { addClientDetailForConsultation, isAddClient } = action.payload;
        return { ...state, addClientDetailForConsultation: addClientDetailForConsultation, isAddClient };
      }
      case actionTypes.SetMobileMedIvServiceBusinessFlow: {
        const { widgetToken, isMobileMedIvServiceFlow, mobileMedIvServiceCode, entityId, entityType, isBusiness, isNewTabOpen, isCallOutService, isDisplayBookingChoice, businessDetail, locationDetail } = action.payload;
        return {
          ...state, widgetToken: widgetToken, isMobileMedIvServiceFlow: isMobileMedIvServiceFlow, mobileMedIvServiceCode: mobileMedIvServiceCode, entityId: entityId, entityType: entityType,
          isBusiness: isBusiness, isNewTabOpen: isNewTabOpen, isCallOutService: isCallOutService, isDisplayBookingChoice: isDisplayBookingChoice, businessDetail: businessDetail,
          locationDetail: locationDetail
        };
      }
      case actionTypes.SetFormClientUserDetailId: {
        const { formClientUserDetailId } = action.payload;
        return { ...state, formClientUserDetailId: formClientUserDetailId };
      }
      case actionTypes.SetDummyBookingFlow: {
        const { bookingDetailResponse } = action.payload;
        return { ...state, bookingDetailResponse: bookingDetailResponse };
      }
      case actionTypes.SetRedirectToBookingDetailPage: {
        const { isRedirectToBookingDetailPage } = action.payload;
        return { ...state, isRedirectToBookingDetailPage: isRedirectToBookingDetailPage, isRedirectToPSFBookingDetailPage: action.payload?.isRedirectToPSFBookingDetailPage };
      }
      case actionTypes.SetRedirectToNewCardFromMeds: {
        return { ...state, isRedirectToNewCardFromMeds: true };
      }
      case actionTypes.ClearRedirectToNewCardFromMeds: {
        return { ...state, isRedirectToNewCardFromMeds: initialWidgetState.isRedirectToNewCardFromMeds };
      }
      case actionTypes.SetServiceConsultBookingResponse: {
        const { serviceConsultForBookingList } = action.payload;
        return { ...state, serviceConsultForBookingList: serviceConsultForBookingList };
      }
      case actionTypes.ClearMobileMedIvFlow: {
        return { ...state, isMobileMedIvServiceFlow: initialWidgetState.isMobileMedIvServiceFlow, mobileMedIvServiceCode: initialWidgetState.mobileMedIvServiceCode };
      }
      case actionTypes.SetRedirectToCancelBookingPage: {
        const { isRedirectToCancelBookingPage } = action.payload;
        return { ...state, isRedirectToCancelBookingPage: isRedirectToCancelBookingPage };
      }
      case actionTypes.SetBackFromConsultationSummaryPage: {
        const { isBackFromConsultationSummaryPage } = action.payload;
        return { ...state, isBackFromConsultationSummaryPage: isBackFromConsultationSummaryPage };
      }
      case actionTypes.setConsultationListPage: {
        const { isBackFromConsultationListPage } = action.payload;
        return { ...state, isBackFromConsultationListPage };
      }
      case actionTypes.setPreAssessmentDetails: {
        return {...state, preAssessmentDetails: action.payload || {}}
      }
      case actionTypes.updateLastServiceConsultAndConsultRequired: {
        return {...state, isServiceConsultRequired: action.payload?.isServiceConsultRequired, lastServiceConsultDetails: action.payload?.lastServiceConsultDetails}
      }
      case actionTypes.updateOrderMedicationDetails: {
        return {...state, psfOrderMedication: action.payload || {}}
      }
      case actionTypes.UpdateWhiteLabelDetails: {
        return {...state, whiteLabelDetails: action.payload}
      }
      case actionTypes.ClearWidgetDetails: {
        return { ...initialWidgetState }
      }
      case actionTypes.updateConsultAfterLabDetails: {
        return {...state, consultAfterLabDetails: {...state.consultAfterLabDetails, ...action.payload}}
      }
      case actionTypes.updateParentWidgetDetails : {
        return {...state, parentWidgetDetails: {...state.parentWidgetDetails, ...action.payload}}
      }
      case actionTypes.clearParentWidgetDetails : {
        return {...state, childWidgetDetails: initialWidgetState.parentWidgetDetails}
      }
      default:
        return state;
    }
  });

