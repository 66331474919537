import React, { useEffect, useState } from "react";
import { DataConstant, UrlConstant } from "../../constants";
import { useIntl } from "react-intl";
import Skeleton from '@material-ui/lab/Skeleton';

import { findMessage, isValidPhoneNumber, returnToken } from "../../../utils/helper";
import { NotificationManager } from "react-notifications";
import { handleFetchGet, handleFetchPost } from "../../../utils/widgetApiCallsProvider";
import CustomSelect from "../../components/customSelect";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "./_redux/widgetRedux";
import  { IListItem, MobileBusinessProfile } from "./widget-businessProfile-2";
import styles from "./widgetParent.module.css";
import widgetLayoutStyles from "./widgetLayout.module.css"; 
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import PrimaryButton from "./widget-checkout2_0/PrimaryButton/PrimaryButton";
import { appActionTypes } from "./_redux/appRedux";

import SelectStyle from '../../components/customSelect/CustomSelect.module.css';

const { REACT_APP_IMAGEURL } = process.env;

const ParentBusinessPage = ({
  history,
  match: {
    params: { code },
  },
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [parentBusinessDetails, setParentBusinessDetails] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const appDetail = useSelector((state) => state.app);

  const decodeString = (code)=>{
    try{
      if (typeof code === "string") {
        const parts = atob(code).split("/");

        return {parentBusinessId: parts?.[0],affiliateId:  Number(parts?.[1]), promoCode: parts?.[2]}
      }
      // If any condition is not met, throw an error
      throw new Error("Invalid format");
    }
    catch(error){
      NotificationManager.error(intl.formatMessage({id: "InvalidCode"}));
      return {};
    }
  }

  useEffect(() => {
    const wsrvUrl = `https://wsrv.nl/?url=${parentBusinessDetails?.businessProfilePic}&w=16&h=16&output=png&filename=favicon.ico`;
    document.title = parentBusinessDetails?.businessName;
    const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = wsrvUrl
    document.head.appendChild(favicon);
  }, [parentBusinessDetails])

    const getCouponDetails = (affiliatePromocode)=>{
      handleFetchGet(UrlConstant.widget.getAffiliateCouponDetails(affiliatePromocode)).then((response)=> {
        if(response && response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data){
            dispatch({
              type: appActionTypes.SetShowAffiliatePromoCodeModal,
              payload: {
                showAffilitatePromoCodeModal: true,
                affiliatePromoCodeDetails: response?.data
              },
            });
          
        }
        else{
          if (response?.message && response.message.includes(DataConstant.Exception)) {
            NotificationManager.error(DataConstant.ExceptionMsg);
          }
          else if (response?.message) {
            NotificationManager.error(findMessage(response.message));
          }
        }
      })
    }

  useEffect(() => {
    if (code) {
      const { parentBusinessId, affiliateId, promoCode} = decodeString(code);
      if (!parentBusinessId) {
        history.push(returnToken())
      } else {
        handleFetchPost(
          `${UrlConstant.common.GetParentBusiness}`,
          JSON.stringify({ parentBusinessId }),
        ).then((response) => {
          if (
            response &&
            response.status?.toLowerCase() ===
            DataConstant.apiResponseStatus.success.toLowerCase() &&
            response.data
          ) {
            const businessDeatilsRes = response?.data?.entities?.[0];
            if(promoCode){
              getCouponDetails(promoCode);
            }

            setParentBusinessDetails({
              ...businessDeatilsRes,
              businessName: businessDeatilsRes?.name,
              businessId: businessDeatilsRes?.parentBusinessId,
              businessProfilePic: businessDeatilsRes?.profileImage,
              businessPhoneNumber: businessDeatilsRes?.phone,
              businessAdddress: businessDeatilsRes?.fullAddress
            });
            if (businessDeatilsRes?.childBusiness.length > 0) {
              dispatch([
                {
                  type: appActionTypes.UpdateAffiliateDetails,
                  payload: {
                    url: window.location.href,
                    code,
                    affiliateId,
                    promoCode,
                  }
                },
                {
                  type: appActionTypes.SetChildBusinessCodeList,
                  payload: businessDeatilsRes?.childBusiness?.map((item) => item?.widgetCode),
                },

              ])
            } else {
              dispatch([
                {
                  type: actionTypes.ClearWidgetDetails,
                },
                {
                  type: appActionTypes.UpdateAffiliateDetails,
                  payload: {
                    url: window.location.href,
                    code,
                    affiliateId,
                    promoCode,
                  }
                },
                {
                  type: appActionTypes.SetChildBusinessCodeList,
                  payload: [],
                },
              ])
              history.push(returnToken());
            }
          } else {
            NotificationManager.error(response?.message);
          }
        }).catch((error) => {
          NotificationManager.error(error?.message);
        });

        
      }
    }
  }, [code])

  useEffect(() => {
    if (appDetail?.childBusinessCodeList?.length === 1) {
      history.push(`/widget-business/${appDetail?.childBusinessCodeList?.[0]}`)
    }
  }, [appDetail?.childBusinessCodeList])

  const listItems = [
    {
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessLocation.svg")} />,
      text: parentBusinessDetails?.businessAdddress
    },
  ];

  if(isValidPhoneNumber(parentBusinessDetails?.businessPhoneNumber)){
    listItems.push({
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/businessPhone.svg")} />,
      text: parentBusinessDetails?.businessPhoneNumber,
      tel: true,
      copyBtn: true,
    });
  }

  if (!parentBusinessDetails) return <ParentShimmer />;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <MobileBusinessProfile showBusinessInMobile={true} businessDetail={parentBusinessDetails} />
      </div>
      <div className={styles.left}>
        <div className={styles.leftInnerContainer}>
          {parentBusinessDetails?.businessId ? <>
            <div className={widgetLayoutStyles.detailsImageContainer}>
              <img src={parentBusinessDetails?.businessProfilePic ? parentBusinessDetails?.businessProfilePic : REACT_APP_IMAGEURL + DataConstant.media.defaultprofilepic} alt="Business Profile" />
            </div>
            <ul className={styles.listContainer}>
              <span className={styles.header}>
                {parentBusinessDetails?.businessName}
              </span>
              <span className={widgetLayoutStyles.description}>
                {parentBusinessDetails?.businessDescription}
              </span>
              {listItems.map((item, index) => (
                <IListItem key={index} index={index} item={item} />
              ))}
            </ul>
          </> : null}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.rightInnerContainer}>
          <div className={styles.rightInnerTopContainer}>
            <span className={styles.title}>{intl.formatMessage({id: "ParentBusiness.SelectYourState"})}</span>
            <span className={styles.subTitle}>
              {intl.formatMessage({id: "ParentBusiness.SelectYourStateText"})}
            </span>
            <CustomSelect selectedBusiness={selectedBusiness} childBusinessList={parentBusinessDetails?.childBusiness} onSelect={setSelectedBusiness} />
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton roundedFull={true} disabled={!selectedBusiness} fullWidth onClick= {() => history.push(`/widget-business/${selectedBusiness?.widgetCode}`) }>{intl.formatMessage({id: "button.Continue"})}</PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ParentShimmer = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className="d-flex align-items-center gap-12">
          
          <div className={widgetLayoutStyles.mDetailsImageContainer}>
            <Skeleton height={150} width={150} />
          </div>
          <div className={widgetLayoutStyles.mBusinessMobileView}>
            <h3 className={`text-dark text-capitalize text-left ${widgetLayoutStyles.header}`}>
              <Skeleton height={40} width={200} />
            </h3>
            <span className={styles.header}>
              <Skeleton height={30} width={50} />
            </span>
            
          </div>
        </div>
      </div>
      <div className={styles.left}>
        <div className={styles.leftInnerContainer}>
          <div className={widgetLayoutStyles.detailsImageContainer}>
            <Skeleton height={250} width={250} />
          </div>
          <ul className={styles.listContainer}>
            <span className={styles.header}>
              <Skeleton height={70} width={250} />
            </span>
            <span className={widgetLayoutStyles.description}>
              <Skeleton height={30} width={300} />
            </span>
            <div
              className={`px-4 ${widgetLayoutStyles.listItem}`}
            >
              <Skeleton height={40} width={40} />
              <Skeleton height={40} width={200}/>
              <Skeleton height={40} width={40} />
            </div>
            <div
              className={`px-4 ${widgetLayoutStyles.listItem}`}
            >
              <Skeleton height={40} width={40} />
              <Skeleton height={40} width={200}/>
              <Skeleton height={40} width={40} />
            </div>
          </ul>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.rightInnerContainer}>
          <div className={styles.rightInnerTopContainer}>
            <span className={styles.title}>
              <Skeleton height={50} width={200} />
            </span>
            <span className={styles.subTitle}>
              <Skeleton height={40} width={300} />
            </span>
            <div className={SelectStyle["custom-select-container"]}>
              <div className={SelectStyle["custom-select-input"]}>
                <span className="svg-icon p-2">
                  <Skeleton height={40} width={40} />
                </span>
                
              </div>

              <div className={SelectStyle.gridContainer}>
                {Array.from({ length: 18 }).map((business, index) => (
                    <div
                      key={index}
                      className={SelectStyle.gridItem}
                    >
                      <Skeleton height={40} width={100} />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton roundedFull={true} disabled={true} fullWidth>
              
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentBusinessPage;
