import React, { Suspense, useEffect } from "react";
import { Redirect, Switch, useParams, Route, useLocation } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { RoutesList } from "./routeList";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ActionLoadingDialog } from "../app/components/loader";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Slide } from "@material-ui/core";
import { signOut } from "../utils/authProvider";
import { useIntl } from "react-intl";
import { DataConstant, UrlConstant } from "./constants";
import TagManager from "react-gtm-module";
import { useVerifyBusiness } from "./modules/widget/widget-verifyBusiness";
import { handleFetchGet, handleFetchPost } from "../utils/widgetApiCallsProvider";
import moment from "moment";
import { actionTypes } from "./modules/widget/_redux/widgetRedux";
import { EncryptText, findMessage, formatAddressString } from "../utils/helper";
import { NotificationManager } from "react-notifications";
import useBusinessDetails from "./hooks/useBusinessDetails";
import { useLocalStorage } from "./components/useLocalStorage";
import { handleAuthFetchGet } from "../utils/apiCallsProvider";
import LogRocket from 'logrocket';
import ReactGA from "react-ga4";
import useServiceDetails from "./hooks/useServiceDetails";
import WidgetLayout from "./modules/widget/widgetLayout";
import HcpAcceptDecline from "./modules/widget/widget-hcpAcceptDecline";
import ParentBusinessPage from "./modules/widget/widget-parentBusiness";


const {
  REACT_APP_ENABLE_GTM,
  REACT_APP_GTM_KEYS,
  REACT_APP_WHITELABELNAME_DISPLAYNAME,
  REACT_APP_ENABLE_ANALYTICS,
  REACT_APP_ANALYTICS_FALLBACK_PROPERTY
} = process.env;

export default function BasePage() {
  const { actionLoader, isUnAuthorizedByAPI } = useSelector(
    (state) => ({ actionLoader: state.loader.actionLoader, isUnAuthorizedByAPI: state.auth.isUnAuthorizedByAPI }),
    shallowEqual
  );
  const intl = useIntl();
  const widgetDetail = useSelector(state => state.widget);

  useEffect(()=>{
    if (!widgetDetail?.widgetCode || !widgetDetail?.businessDetail?.businessId || REACT_APP_ENABLE_GTM !== "true") return;
  
    try {
      const GMTKeys = JSON.parse(REACT_APP_GTM_KEYS || "[]");
      const defaultGtmID = GMTKeys?.find(d => d.businessId === widgetDetail.businessDetail.businessId);
      if (defaultGtmID?.gtmId) {
        const tagManagerArgs = {
          gtmId: defaultGtmID?.gtmId,
          dataLayer: DataConstant.gtmEvents.homePage,
        };
        TagManager.initialize(tagManagerArgs);
      }
    } catch (error) {
      console.error('Error parsing GMT keys:', error);
    }
  },[widgetDetail?.widgetCode])

  useEffect(() => {
    const wsrvUrl = `https://wsrv.nl/?url=${widgetDetail?.businessDetail?.businessProfilePic}&w=16&h=16&output=png&filename=favicon.ico`;
    document.title = widgetDetail?.businessDetail?.businessName;
    const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = wsrvUrl
    document.head.appendChild(favicon);
  }, [widgetDetail?.businessDetail])
  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {actionLoader && <ActionLoadingDialog isLoading={actionLoader} widgetCode={widgetDetail?.widgetCode} />}
      {<>
        <Dialog
          open={isUnAuthorizedByAPI}
          keepMounted
          onClose={signOut}
          aria-describedby="invalid_token_logout_popup"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogContent>
            <DialogContentText id="alert-invalid_token_logout_popup">
            {intl.formatMessage({ id: "SessionExpire.Message" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={signOut}>{intl.formatMessage({ id: "ProfileMenu.Logout" })}</Button>
          </DialogActions>
        </Dialog>
      </>}
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          // <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/b/:code" component={ParentBusinessPage} />
        <Route path="/widget-business/:code" component={NewPathCommonRoute} />
        {RoutesList.map((route) => {
          const newPathEnable = route?.newPathEnable
          return (
            !newPathEnable && <ContentRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          );
        })}
        <Route path="/rsvp/:code" component={HcpAcceptDecline} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

const NewPathCommonRoute = () => {

  const code = useParams()?.code;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceIds = queryParams.get('serviceids');
  const facilityId = queryParams.get('facilityid');

  // Get Redeux Data
  const dispatch = useDispatch();
  //const currentTab = useSelector(state => state.widget.currentTab);
  const { isAuthorized, isSigninInProgress, userType, currentTab, userDetailId } = useSelector(
    ({ auth, widget }) => ({
      isAuthorized: auth.user != null,
      isSigninInProgress: auth.isSigninInProgress,
      userType: auth.userType,
      currentTab: widget.currentTab,
      userDetailId: widget.userDetailId,
    }),
    shallowEqual
  );

  const widgetDetail = useSelector(state => state.widget);
  const isVSDH = REACT_APP_WHITELABELNAME_DISPLAYNAME?.toLowerCase() === DataConstant.whiteLabelNames.vsdigital.name.toLowerCase()
  // end Redeux Data

  // hook define 
  const [userDetail, setUserDetail] = useLocalStorage(DataConstant.userDetailTokenKey);
  const [affiliateDetail, setAffiliateDetail] = useLocalStorage(DataConstant.affiliateDetailTokenKey);

  const verifyWidegetCode =  useVerifyBusiness(code);
  const widgetCode = widgetDetail?.widgetCode;

  const isDecoded = widgetCode == code;

  //#region verifiy widget code if code not match or present in redux
  useEffect(() => {
    if (!isDecoded) {
      verifyWidegetCode();
    }
  }, [code])
  //#endregion

  //#region Get User Types for typewiselogin if user is authorised
  useEffect(() => {
    if (!isDecoded) return;
    if (isAuthorized && userDetailId === 0 && isSigninInProgress === false) {
      getUserTypes();
      if (process.env.REACT_APP_IS_LOGROCKET_ENABLED) {
        LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT_ID);
      }
    }
  }, [isAuthorized, widgetDetail?.widgetCode]);
  //#endregion

  //#region Google Analytics
  useEffect(() => {
    if (!isDecoded) return;
    if (REACT_APP_ENABLE_ANALYTICS == "true") {
      // fallback analytics property
      const defaultGTag = REACT_APP_ANALYTICS_FALLBACK_PROPERTY;

      let trackingId = defaultGTag;

      if (
        widgetDetail.googleAnalyticsId !== "" &&
        widgetDetail.googleAnalyticsId !== undefined &&
        widgetDetail.googleAnalyticsId !== null
      ) {
        trackingId = widgetDetail.googleAnalyticsId;
      }
      ReactGA.initialize(trackingId);
      ReactGA.event(DataConstant.analyticsEvents.homePage);
    }
  }, [widgetDetail?.widgetCode]);
  //#endregion

  const goToTab = (tabName) => {
    if (currentTab === tabName) return;
    dispatch({
      type: actionTypes.SetWidgetTab,
      payload: {
        currentTab: tabName
      },
    });
  };

  const setBookingChoice = (isCallOutService, isDisplayBookingChoice) => {
    dispatch({
      type: actionTypes.SetBookingChoice,
      payload: {
        isCallOutService: isCallOutService,
        isDisplayBookingChoice: isDisplayBookingChoice
      },
    });
  };

  const dispatchLocationDetail = (lat, lng, searchText, country, zipcode) => {
    dispatch({
      type: actionTypes.SetLocationDetail,
      payload: {
        searchText: searchText,
        address: searchText,
        lat: lat,
        long: lng,
        country: country,
        zipcode: zipcode
      }
    });
  };

  const getUserByIdList = (id) => {
    handleAuthFetchGet(`${UrlConstant.userManagement.getUserById}/${id}`).then(
      (response) => {
        if (
          response &&
          response.status?.toLowerCase() ===
            DataConstant.apiResponseStatus.success.toLowerCase() &&
          response.data
        ) {
          const {
            userId,
            email,
            phone,
            userDetailId,
            userType,
            isTestAccount,
          } = response.data;

          if(isVSDH){
            let fullAddress = [response?.data?.address1 || ""];
            if (response?.data?.address2) {
              fullAddress.push(response?.data?.address2 || "");
            }
  
            if (response?.data?.googleMapJson) {
              const googleMapAddress = JSON.parse(response.data.googleMapJson);
              if (googleMapAddress?.formatted_address) {
                fullAddress.push(googleMapAddress?.formatted_address || "");
              }
            }
            const formatedFullAddress = fullAddress.filter(a => a).join(", ");
            dispatchLocationDetail(response?.data?.currentLat,response?.data?.currentLong, formatedFullAddress, response?.data?.countryName,response?.data?.zipcode )
          }
          if (process.env.REACT_APP_IS_LOGROCKET_ENABLED) {
            LogRocket.identify(email, {
              userId: userId,
              phone: phone,
              userDetailId: userDetailId,
              userType: userType,
              isTestAccount: isTestAccount,
            });
          }
        }
      }
    );
  };

  //#region get whitelabels details and update banner color

  const getWhiteLabelsDetails = () => {
    return handleFetchGet(
      UrlConstant.common.GetWhiteLabelDetails,
      JSON.stringify({
        currentDateTime : moment().format(DataConstant.dateFormats.YYYYMMDD),
        currentTimeStr : moment().format(DataConstant.dateFormats.HHmmss),
      })
    )
  }

  const processWhiteLabelDetails = (response) => {
    if (
      response &&
      response.status?.toLowerCase() ===
      DataConstant.apiResponseStatus.success.toLowerCase() &&
      response.data
    ) {
      dispatch({
        type: actionTypes.UpdateWhiteLabelDetails,
        payload: response.data,
      });
    }
    else {
      if (response?.message && response.message.includes(DataConstant.Exception)) {
        NotificationManager.error(DataConstant.ExceptionMsg);
      }
      else if (response?.message) {
        NotificationManager.error(findMessage(response.message));
      }
    }
  };

  useEffect(() => {
    if (!isDecoded) return;
    getWhiteLabelsDetails().then((whiteLabelDetailsResponse) => {
      processWhiteLabelDetails(whiteLabelDetailsResponse);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      NotificationManager.error(DataConstant.ExceptionMsg);
    });
  }, [widgetDetail?.widgetCode]);

  //#endregion
  
  //#region get user type and widget booking choice list, register user in widget, update business details

  const registerUser = (ClientUserDetailid) => {
    handleFetchPost(
      UrlConstant.common.getWidgetBookingChoiceList,
      JSON.stringify({
        currentDateTime : moment().format(DataConstant.dateFormats.YYYYMMDD),
        currentTimeStr : moment().format(DataConstant.dateFormats.HHmmss),
        ClientUserDetailid,
      })
    )
  };
  
  const { updateBusinessDetails } = useBusinessDetails();

  const getUserTypes = () => {
    handleAuthFetchGet(
      UrlConstant.userManagement.userTypeListForTypeWiseLogin
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data && response.data.length > 0
      ) {
        const affiliateDetail = response?.data?.find((i) => {
          return i?.userTypeName === DataConstant?.userType?.Affiliate;
        });
        const userTypeDetail = response.data.find((i) => {
          return i.userTypeName === DataConstant.userType.Client;
        }) || affiliateDetail;
        if (userTypeDetail != null) {
          setUserDetail(EncryptText(JSON.stringify(userTypeDetail)));
          setAffiliateDetail(EncryptText(JSON.stringify(affiliateDetail)));
          dispatch({
            type: actionTypes.SetUserTypeDetail,
            payload: {
              userType: userTypeDetail?.userTypeName,
              userTypeId: userTypeDetail?.userTypeId,
              userDetailId: userTypeDetail?.userDetailId,
              userId: userTypeDetail?.userId,
              isTestAccount : userTypeDetail?.isTestAccount,
              affiliateUserDetailId: affiliateDetail?.userDetailId
            },
          });
          //#region add api to send userDetailId to register user
          registerUser(userTypeDetail?.userDetailId);
          //#endregion
          getUserByIdList(userTypeDetail?.userDetailId);
          dispatch({
            type: "[Set UserDetail] Action",
            payload: {
              userDetailId: userTypeDetail?.userDetailId,
              userType: userTypeDetail?.userTypeName,
              userTypeId: userTypeDetail?.userTypeId,
              businessName: userTypeDetail?.businessName,
              businessId: userTypeDetail?.businessId,
              whiteLabel: userTypeDetail?.whiteLabel,
              currency: userTypeDetail?.currency,
              sessionId: userTypeDetail?.sessionId,
              allowBusinessToAddMembership: userTypeDetail?.allowBusinessToAddMembership,
              allowCashPayment: userTypeDetail?.allowCashPayment,
              userHasClientRole: userTypeDetail?.userTypeName === DataConstant.userType.Client,
              affiliateUserDetailId: affiliateDetail?.userDetailId,
            },
          }
            
          );
          updateBusinessDetails();
          if (currentTab === DataConstant.tabs.serviceProvider)
            goToTab(DataConstant.tabs.note);
        }
        else
        {
            NotificationManager.error(DataConstant.clientAccountNotFound,DataConstant.moduleName.serviceProvider,DataConstant.notificationUserTimeout);
            setTimeout(() => {
              signOut();
          },2000);
        }
      } else {
        if (response?.message && response.message.includes(DataConstant.Exception)) {
          NotificationManager.error(DataConstant.ExceptionMsg);
        }
        else if (response?.message) {
          NotificationManager.error(DataConstant.clientAccountNotFound,DataConstant.moduleName.serviceProvider,DataConstant.notificationUserTimeout);
        }
        setTimeout(() => {
          signOut();
      },2000);
      }
    });
  };

  const getWidgetBookingChoiceList = () => {
    handleFetchPost(
      UrlConstant.common.getWidgetBookingChoiceList,
      JSON.stringify({
        currentDateTime : moment().format(DataConstant.dateFormats.YYYYMMDD),
        currentTimeStr : moment().format(DataConstant.dateFormats.HHmmss),
      })
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        const { entities, isCallOutService } = response.data;
        dispatch([
          {
            type: actionTypes.SetEntityDetail,
            payload: {
              entityId: response?.data?.entityId,
              entityType: response?.data?.entityType,
              isBusiness: response?.data?.entityType === DataConstant.userType.Business ? true : false,
              isNewTabOpen: response?.data?.isNewTabOpen,
              headerTitle: response.data?.openingText,
            },
          },
          {
          type: actionTypes.UpdateBusinessDetail,
          payload: {
            businessId: response?.data?.businessId,
            businessName: response?.data?.businessName,
            businessAdddress: formatAddressString(response?.data?.businessAddress1, response?.data?.businessAddress2, response?.data?.businessCity, response?.data?.businessState, response?.data?.businessZipCode, response?.data?.businessCountry, null, null),
            businessProfilePic: response?.data?.businessProfilePic,
            businessRating: response?.data?.businessRating,
            noPenaltyUptoHours: response?.data?.noPenaltyUptoHours,
            cancelledBookingCharge: response?.data?.cancelledBookingCharge,
            businessZipcode: response?.data?.businessZipCode,
            businessCountry: response?.data?.businessCountry,
            businessDescription : response?.data?.businessDescription,
            businessTagline : response?.data?.businessTagline,
            businessPhoneNumber : response?.data.businessPhoneNumber,
            isCallOutService: response?.data?.isCallOutService,
            businessState: response?.data?.businessState,
            widgetBannerColor: response.data?.widgetBannerColor,
          },
        }]);
        if (response?.data?.entityType === DataConstant.userType.serviceProvider) {
          dispatch({
            type: actionTypes.SetServiceProviderProfileDetail,
            payload: {
              serviceProviderUserDetailId: response?.data?.serviceProviderUserDetailId,
              serviceProviderName: response?.data?.serviceProviderName,
              serviceProviderReview: response?.data?.serviceProviderReview,
              serviceProviderProfileImage: response?.data?.serviceProviderProfileImage,
              serviceProviderDefaultBusinessId: response?.data?.serviceProviderDefaultBusinessId,
              serviceProviderBioDetail : response?.data?.serviceProviderBioDetail,
            },
          });
        }
        var isDisplayBookingChoice = false;
        if (!isCallOutService && entities && entities.length > 0) {
          isDisplayBookingChoice = true;
          goToTab(DataConstant.tabs.widgetMain);
        }
        else if (isCallOutService || !isCallOutService) {
          setBookingChoice(isCallOutService, isDisplayBookingChoice);
          goToTab(!isVSDH ? !isCallOutService ? DataConstant.tabs.facility : DataConstant.tabs.location : DataConstant.tabs.service);
        }
      }
      else {
        if (response?.message && response.message.includes(DataConstant.Exception)) {
          NotificationManager.error(DataConstant.ExceptionMsg);
        }
        else if (response?.message) {
          NotificationManager.error(findMessage(response.message));
        }
      }
    });
  };

  useEffect(()=>{
    if (!isDecoded) return;
    if(currentTab === DataConstant.tabs.widgetMain) {
      getWidgetBookingChoiceList();
    }
    if(widgetDetail?.userDetailId){
      getUserByIdList(widgetDetail?.userDetailId)
    }

  }, [currentTab === DataConstant.tabs.widgetMain, widgetDetail?.widgetCode])

  //#endregion

  //#region Populate service data if serviceids exists
  const decodeService = useServiceDetails();

  useEffect(() => {
    const serviceIdSelected = Number(serviceIds?.split(" ")?.[0]);
    const selectedFacilityId = Number(facilityId);

    if (!isDecoded || !serviceIdSelected) return;
    decodeService(serviceIdSelected, selectedFacilityId);
  }, [widgetDetail?.widgetCode]);

  //#endregion

  if (isAuthorized && !userDetail) {
    return <WidgetLayout history={null} showBusinessInMobile={false} />
  }

  return (
    <Switch>
      {RoutesList.map((route) => {
        const newPathEnable = route?.newPathEnable
        return (
          newPathEnable && <ContentRoute
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        );
      })}
      <Redirect to="error/error-v1" />
    </Switch>
  )
}
